<template>
    <van-form @submit="onSubmit">
        <div class="form-title">客户信息</div>
        <van-field
            v-model="form.customername"
            name="客户姓名"
            label="客户姓名"
            placeholder="请输入客户姓名"
            :rules="[{ required: true, message: '请填写客户姓名' }]"
        />
        <van-field
            v-model="form.customertel"
            type="tel"
            name="客户电话"
            label="客户电话"
            placeholder="请输入客户电话"
            :rules="[{ required: true, message: '请填写客户电话' }]"
        />
        <div class="form-title">意向房源信息</div>
        <van-field
            readonly
            clickable
            name="picker"
            :value="form.area"
            is-link
            label="意向区域"
            placeholder="点击选择意向区域"
            @click="showArea = true"
            :rules="[{ required: true, message: '请选择意向区域' }]"
        />
        <van-field
            readonly
            clickable
            name="picker"
            :value="form.district"
            is-link
            label="意向商圈"
            placeholder="点击选择意向商圈"
            @click="showDis = true"
            :rules="[{ required: true, message: '请选择意向商圈' }]"
        />
        <van-field
            v-model="form.customeraddress"
            name="意向房源地址"
            label="意向房源地址"
            placeholder="请输入意向房源地址"
            :rules="[{ required: true, message: '请填写意向房源地址' }]"
        />
        <van-row>
            <van-col span="12">
                <van-field
                    input-align="right"
                    type="number"
                    v-model="form.housingarea"
                    name="意向面积"
                    label="意向面积"
                    placeholder="面积(㎡)"
                    :rules="[{ required: true, message: '请填写意向面积' }]"
                />
            </van-col>
            <van-col span="12">
                <van-field
                    style="padding-left:0 !important;"
                    type="number"
                    v-model="form.housingarea2"
                    name="意向面积1"
                    label="—"
                    label-width="10"
                    placeholder="面积(㎡)"
                    :rules="[{ required: true, message: '请填写意向面积' }]"
                />
            </van-col>
        </van-row>
        <van-field
            readonly
            clickable
            name="picker"
            :value="form.face"
            is-link
            label="朝向"
            placeholder="点击选择朝向"
            @click="showFace = true"
            :rules="[{ required: true, message: '请选择朝向' }]"
        />
        <van-field
            readonly
            clickable
            name="picker"
            :value="form.unit"
            is-link
            label="户型"
            placeholder="点击选择户型"
            :rules="[{ required: true, message: '请选择户型' }]"
            @click="show = true"
        />
        <van-field
            readonly
            clickable
            name="picker"
            :value="form.floor"
            is-link
            label="楼层"
            placeholder="点击选择楼层"
            :rules="[{ required: true, message: '请选择楼层' }]"
            @click="show1 = true"
        />
        <van-field
            readonly
            clickable
            name="picker"
            :value="form.decoration"
            is-link
            label="装修"
            placeholder="点击选择装修"
            @click="showDeco = true"
            :rules="[{ required: true, message: '请选择装修' }]"
        />
        <div class="form-title">意向付款信息</div>
        <van-row>
            <van-col span="12">
                <van-field
                    input-align="right"
                    type="number"
                    v-model="form.rent"
                    name="意向房价"
                    label="意向房价"
                    placeholder="万元"
                    :rules="[{ required: true, message: '请填写意向房价' }]"
                />
            </van-col>
            <van-col span="12">
                <van-field
                    style="padding-left:0 !important;"
                    type="number"
                    v-model="form.rent2"
                    name="意向房价1"
                    label="—"
                    label-width="10"
                    placeholder="万元"
                    :rules="[{ required: true, message: '请填写意向租金' }]"
                />
            </van-col>
        </van-row>
        <van-field
            readonly
            clickable
            name="picker"
            :value="form.termsofpayment"
            is-link
            label="意向付款方式"
            placeholder="点击选择意向付款方式"
            :rules="[{ required: true, message: '请选择意向付款方式' }]"
            @click="show2 = true"
        />
        <van-field
            type="textarea"
            maxlength="300"
            show-word-limit
            v-model="form.remark"
            name="备注信息"
            label="备注信息"
            placeholder="请输入备注信息"
            :rows="2"
            autosize
        />
        <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit" :loading="loading" loading-text="正在提交">提交</van-button>
        </div>

        <van-popup v-model="show" round position="bottom">
            <van-cascader
                v-model="form.unit1"
                title="请选择户型"
                :options="options"
                @close="show = false"
                @finish="onFinish"
            />
        </van-popup>
        <van-popup v-model="show1" round position="bottom">
            <van-cascader
                v-model="form.floor1"
                title="请选择楼层"
                :options="options1"
                @close="show1 = false"
                @finish="onFinish1"
            />
        </van-popup>
        <van-popup v-model="show2" round position="bottom">
            <van-picker
                show-toolbar
                value-key="dictLabel"
                :columns="fukuan"
                @confirm="selFu"
                @cancel="show2 = false"
            />
        </van-popup>
        <van-popup v-model="showArea" position="bottom">
            <van-picker
                show-toolbar
                value-key="area"
                :columns="areaArr"
                @confirm="selArea"
                @cancel="showArea = false"
            />
        </van-popup>
        <van-popup v-model="showDis" position="bottom">
            <van-picker
                show-toolbar
                value-key="district"
                :columns="districtArr"
                @confirm="selDis"
                @cancel="showDis = false"
            />
        </van-popup>
        <van-popup v-model="showFace" position="bottom">
            <van-picker
                show-toolbar
                value-key="dictLabel"
                :columns="faceArr"
                @confirm="selFace"
                @cancel="showFace = false"
            />
        </van-popup>
        <van-popup v-model="showDeco" position="bottom">
            <van-picker
                show-toolbar
                value-key="dictLabel"
                :columns="decorationArr"
                @confirm="selDeco"
                @cancel="showDeco = false"
            />
        </van-popup>
    </van-form>
</template>

<script>
    import { getArea, getDict, addMaimaicustomer } from '@/api/api'
    export default {
        name: 'DealCustomer',
        data() {
            return {
                form: {
                    customername: '',
                    customertel: '',
                    areaid: '',
                    area: '',
                    districtid: '',
                    district: '',
                    customeraddress: '',
                    housingarea: '',
                    housingarea2: '',
                    face: '',
                    unit: '',
                    unit1: '',
                    floor: '',
                    floor1: '',
                    decoration: '',
                    rent: '',
                    rent2: '',
                    termsofpayment: '',
                    termsofpayment1: '',
                    remark: ''
                },
                show: false,
                show1: false,
                show2: false,
                options: [],
                options1: [],
                areaArr: [],
                showArea: false,
                showDis: false,
                districtArr: [],
                showFace: false,
                showDeco: false,
                faceArr: [],
                decorationArr: [],
                loading: false,
                fukuan: []
            }
        },
        methods: {
            onSubmit() {
                let param = JSON.parse(JSON.stringify(this.form))
                param.floor = param.floor.replace(/[\u4e00-\u9fa5]/g,'')
                param.termsofpayment = param.termsofpayment1
                param.unit = param.unit.replace(/[\u4e00-\u9fa5]/g,'')
                delete param.termsofpayment1
                delete param.floor1
                delete param.unit1
                const face = this.faceArr.find(e => e.dictLabel === param.face).dictValue
                const decoration = this.decorationArr.find(e => e.dictLabel === param.decoration).dictValue
                param.face = face
                param.decoration = decoration
                this.loading = true
                console.log(param)
                addMaimaicustomer(param).then(res => {
                    if (res.code === 200) {
                        this.$notify({ type: 'success', message: '提交成功！' })
                        // 跳转买卖客户列表
                        // this.$router.push('/rent')
                        console.log('跳转买卖客户列表')
                    } else {
                        this.$toast(res.msg)
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            onFinish({ selectedOptions }) {
                if (selectedOptions.length === 3) {
                    this.show = false
                    this.form.unit = selectedOptions.map((option) => option.text).join()
                }
            },
            onFinish1({ selectedOptions }) {
                if (selectedOptions.length === 2) {
                    this.show1 = false
                    this.form.floor = selectedOptions.map((option) => option.text).join()
                }
            },
            selFu(val) {
                this.form.termsofpayment = val.dictLabel
                this.form.termsofpayment1 = val.dictValue
                this.show2 = false
            },
            selArea(val) {
                this.showArea = false
                this.form.area = val.area
                this.form.areaid = val.id
                if (val.children) {
                    this.districtArr = val.children
                } else {
                    getDict(val.id).then(res => {
                        if (res.code === 200) {
                            this.districtArr = res.data
                            val.children = res.data
                        }
                    })
                }
            },
            selDis(val) {
                this.form.districtid = val.id
                this.form.district = val.district
                this.showDis = false
            },
            selFace(val) {
                this.form.face = val.dictLabel
                this.showFace = false
            },
            selDeco(val) {
                this.form.decoration = val.dictLabel
                this.showDeco = false
            }
        },
        created() {
            // 户型数组
            let idx = 0
            for (let i = 1; i <= 7; i++) {
                const item = { text: i + '室', value: idx, children: [] }
                idx += 1
                for (let j = 1; j <= 7; j++) {
                    item.children.push({ text: j + '厅', value: idx, children: [] })
                    idx += 1
                    for (let k = 1; k <= 7; k++) {
                        item.children[j - 1].children.push({ text: k + '卫', value: idx })
                        idx += 1
                    }
                }
                this.options.push(item)
            }
            // 楼层数组
            let idxz = 0
            for (let i = 1; i <= 32; i++) {
                const item = { text: '第' + i + '层', value: idxz, children: [] }
                idxz += 1
                for (let j = 1; j <= 32; j++) {
                    item.children.push({ text: '共' + j + '层', value: idxz })
                    idxz += 1
                }
                this.options1.push(item)
            }
            getArea().then(res => {
                if (res.code === 200) this.areaArr = res.data
            })
            this.$smartDict.getDict(['vua_face', 'vua_decoration', 'vua_buy_payment']).then(({
                vua_face,
                vua_decoration,
                vua_buy_payment
            }) => {
                this.faceArr = vua_face
                this.decorationArr = vua_decoration
                this.fukuan = vua_buy_payment
            })
        }
    }
</script>

<style lang="scss" scoped>
.form-title{
    width: calc(100vw - 32px);
    height: 30px;
    line-height: 30px;
    box-sizing: border-box;
    border-bottom: 1px solid #1989fa;
    color: #1989fa;
    font-size: 16px;
    font-weight: bold;
    margin: 12px 16px;
}
</style>