<template>
    <div>
        <van-form @submit="onSubmit">
            <van-field
                style="margin-top:10px;"
                readonly
                clickable
                name="picker"
                :value="type"
                is-link
                label="房源信息"
                placeholder="点击选择房源信息"
                :rules="[{ required: true, message: '请选择房源信息' }]"
                @click="showType = true"
            />
            <van-popup v-model="showType" position="bottom">
                <van-picker
                    show-toolbar
                    :columns="columns"
                    @confirm="onConfirm"
                    @cancel="showType = false"
                />
            </van-popup>
            <template v-if="type === '普租房源' || type === '买卖房源'">
                <div class="form-title">业主信息</div>
                <van-field
                    v-model="form.ownername"
                    name="业主姓名"
                    label="业主姓名"
                    placeholder="请输入业主姓名"
                    :rules="[{ required: true, message: '请填写业主姓名' }]"
                />
                <van-field
                    v-model="form.ownertel"
                    type="tel"
                    name="业主电话"
                    label="业主电话"
                    placeholder="请输入业主电话"
                    :rules="[{ required: true, message: '请填写业主电话' }]"
                />
                <div class="form-title">房源信息</div>
                <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="form.area"
                    is-link
                    label="区域"
                    placeholder="点击选择区域"
                    @click="showArea = true"
                    :rules="[{ required: true, message: '请选择区域' }]"
                />
                <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="form.district"
                    is-link
                    label="商圈"
                    placeholder="点击选择商圈"
                    @click="showDis = true"
                    :rules="[{ required: true, message: '请选择商圈' }]"
                />
                <van-field
                    v-model="form.owneraddress"
                    name="房源地址"
                    label="房源地址"
                    placeholder="请输入房源地址"
                    :rules="[{ required: true, message: '请填写房源地址' }]"
                />
                <van-field
                    type="number"
                    v-model="form.housingarea"
                    name="房屋面积"
                    label="房屋面积"
                    placeholder="请输入房屋面积(㎡)"
                    :rules="[{ required: true, message: '请填写房屋面积' }]"
                />
                <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="form.face"
                    is-link
                    label="朝向"
                    placeholder="点击选择朝向"
                    @click="showFace = true"
                    :rules="[{ required: true, message: '请选择朝向' }]"
                />
                <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="form.unit"
                    is-link
                    label="户型"
                    placeholder="点击选择户型"
                    :rules="[{ required: true, message: '请选择户型' }]"
                    @click="show = true"
                />
                <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="form.floor"
                    is-link
                    label="楼层"
                    placeholder="点击选择楼层"
                    :rules="[{ required: true, message: '请选择楼层' }]"
                    @click="show1 = true"
                />
                <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="form.decoration"
                    is-link
                    label="装修"
                    placeholder="点击选择装修"
                    @click="showDeco = true"
                    :rules="[{ required: true, message: '请选择装修' }]"
                />
                <van-field
                    v-if="type === '买卖房源'"
                    readonly
                    clickable
                    name="picker"
                    :value="form1.property"
                    is-link
                    label="产权"
                    placeholder="点击选择产权"
                    @click="showChan = true"
                    :rules="[{ required: true, message: '请选择产权' }]"
                />
                <van-field
                    v-if="type === '买卖房源'"
                    readonly
                    clickable
                    name="picker"
                    :value="form1.buildingages"
                    is-link
                    label="建筑年代"
                    placeholder="点击选择建筑年代"
                    @click="showNian = true"
                    :rules="[{ required: true, message: '请选择建筑年代' }]"
                />
                <van-field
                    v-if="type === '买卖房源'"
                    readonly
                    clickable
                    name="picker"
                    :value="form1.inspection"
                    is-link
                    label="看房时间"
                    placeholder="点击选择看房时间"
                    @click="showKan = true"
                    :rules="[{ required: true, message: '请选择看房时间' }]"
                />
                <div class="form-title">付款信息</div>
                <van-field
                    v-if="type === '普租房源'"
                    type="number"
                    v-model="form.rent"
                    name="租金"
                    label="租金"
                    placeholder="请输入租金(元/月)"
                    :rules="[{ required: true, message: '请填写租金' }]"
                />
                <van-field
                    v-if="type === '买卖房源'"
                    type="number"
                    v-model="form.rent"
                    name="总价"
                    label="总价"
                    placeholder="请输入总价(万元)"
                    :rules="[{ required: true, message: '请填写总价' }]"
                />
                <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="form.termsofpayment"
                    is-link
                    label="付款方式"
                    placeholder="点击选择付款方式"
                    :rules="[{ required: true, message: '请选择付款方式' }]"
                    @click="show2 = true"
                />
                <van-field
                    type="textarea"
                    maxlength="300"
                    show-word-limit
                    v-model="form.remark"
                    name="备注信息"
                    label="备注信息"
                    placeholder="请输入备注信息"
                    :rows="2"
                    autosize
                />
                <div class="form-title">上传图片和视频</div>
                <van-field name="uploader" label="上传视频">
                    <template #input>
                        <van-uploader v-model="form.videourl" accept="video/*" :after-read="upVideos" :multiple="true" :max-size="500000 * 1024" @oversize="onOversize" />
                    </template>
                </van-field>
                <van-field name="uploader1" label="上传图片">
                    <template #input>
                        <van-uploader v-model="form.imgs" :after-read="upVideos" :multiple="true" />
                    </template>
                </van-field>
                <div style="margin: 16px;">
                    <van-button round block type="info" native-type="submit" :loading="loading" loading-text="正在提交">提交</van-button>
                </div>

                <van-popup v-model="show" round position="bottom">
                    <van-cascader
                        v-model="form.unit1"
                        title="请选择户型"
                        :options="options"
                        @close="show = false"
                        @finish="onFinish"
                    />
                </van-popup>
                <van-popup v-model="show1" round position="bottom">
                    <van-cascader
                        v-model="form.floor1"
                        title="请选择楼层"
                        :options="options1"
                        @close="show1 = false"
                        @finish="onFinish1"
                    />
                </van-popup>
                <van-popup v-model="show2" round position="bottom">
                    <van-cascader
                        v-if="type === '普租房源'"
                        v-model="form.termsofpayment1"
                        title="请选择付款方式"
                        :options="options2"
                        @close="show2 = false"
                        @finish="onFinish2"
                    />
                    <van-picker
                        v-else
                        show-toolbar
                        value-key="dictLabel"
                        :columns="fukuan"
                        @confirm="selFu"
                        @cancel="show2 = false"
                    />
                </van-popup>
                <van-popup v-model="showArea" position="bottom">
                    <van-picker
                        show-toolbar
                        value-key="area"
                        :columns="areaArr"
                        @confirm="selArea"
                        @cancel="showArea = false"
                    />
                </van-popup>
                <van-popup v-model="showDis" position="bottom">
                    <van-picker
                        show-toolbar
                        value-key="district"
                        :columns="districtArr"
                        @confirm="selDis"
                        @cancel="showDis = false"
                    />
                </van-popup>
                <van-popup v-model="showFace" position="bottom">
                    <van-picker
                        show-toolbar
                        value-key="dictLabel"
                        :columns="faceArr"
                        @confirm="selFace"
                        @cancel="showFace = false"
                    />
                </van-popup>
                <van-popup v-model="showDeco" position="bottom">
                    <van-picker
                        show-toolbar
                        value-key="dictLabel"
                        :columns="decorationArr"
                        @confirm="selDeco"
                        @cancel="showDeco = false"
                    />
                </van-popup>
                <van-popup v-model="showChan" position="bottom">
                    <van-picker
                        show-toolbar
                        value-key="dictLabel"
                        :columns="chanquan"
                        @confirm="selChan"
                        @cancel="showChan = false"
                    />
                </van-popup>
                <van-popup v-model="showNian" position="bottom">
                    <van-picker
                        show-toolbar
                        :columns="nianfen"
                        @confirm="selNian"
                        @cancel="showNian = false"
                    />
                </van-popup>
                <van-popup v-model="showKan" position="bottom">
                    <van-picker
                        show-toolbar
                        value-key="dictLabel"
                        :columns="kanfang"
                        @confirm="selKan"
                        @cancel="showKan = false"
                    />
                </van-popup>
            </template>
        </van-form>
        <rent-customer v-if="type === '普租客户'" />
        <deal-customer v-if="type === '买卖客户'" />
    </div>
</template>

<script>
    import { uploadFile, addRent, getArea, getDict, addDeal } from '@/api/api'
    import RentCustomer from '@/components/RentCustomer.vue'
    import DealCustomer from '@/components/DealCustomer.vue'
    export default {
        components: {
            RentCustomer,
            DealCustomer
        },
        data() {
            return {
                type: '普租房源',
                showType: false,
                form: {
                    ownername: '',
                    ownertel: '',
                    areaid: '',
                    area: '',
                    districtid: '',
                    district: '',
                    owneraddress: '',
                    housingarea: '',
                    face: '',
                    unit: '',
                    unit1: '',
                    floor: '',
                    floor1: '',
                    decoration: '',
                    rent: '',
                    termsofpayment: '',
                    termsofpayment1: '',
                    remark: '',
                    videourl: [],
                    imgs: []
                },
                form1: {
                    property: '',
                    propertyid: '',
                    buildingages: '',
                    inspection: '',
                    inspectionid: ''
                },
                show: false,
                show1: false,
                show2: false,
                options: [],
                options1: [],
                options2: [],
                columns: ['普租房源', '买卖房源', '普租客户', '买卖客户'],
                areaArr: [],
                showArea: false,
                showDis: false,
                districtArr: [],
                showFace: false,
                showDeco: false,
                faceArr: [],
                decorationArr: [],
                loading: false,
                chanquan: [],
                showChan: false,
                kanfang: [],
                showKan: false,
                fukuan: [],
                showFu: false,
                showNian: false,
                nianfen: []
            }
        },
        methods: {
            onOversize() {
                this.$toast('视频文件不能超过500MB')
            },
            onSubmit() {
                let param = JSON.parse(JSON.stringify(this.form))
                param.floor = param.floor.replace(/[\u4e00-\u9fa5]/g,'')
                param.termsofpayment = this.type === '买卖房源' ? param.termsofpayment1 : param.termsofpayment.replace(/[\u4e00-\u9fa5]/g,'')
                param.unit = param.unit.replace(/[\u4e00-\u9fa5]/g,'')
                delete param.floor1
                delete param.termsofpayment1
                delete param.unit1
                param.imgs = param.imgs.map(e => e.url).join(',')
                param.videourl = param.videourl.map(e => e.url).join(',')
                const face = this.faceArr.find(e => e.dictLabel === param.face).dictValue
                const decoration = this.decorationArr.find(e => e.dictLabel === param.decoration).dictValue
                param.face = face
                param.decoration = decoration
                if (this.type === '买卖房源') {
                    console.log(param)
                    console.log(this.form1)
                    const form1 = {
                        property: this.form1.propertyid,
                        buildingages: this.form1.buildingages,
                        inspection: this.form1.inspectionid
                    }
                    param = Object.assign(param, form1)
                }
                this.loading = true
                const cb = this.type === '买卖房源' ? addDeal : addRent
                cb(param).then(res => {
                    if (res.code === 200) {
                        this.$notify({ type: 'success', message: '提交成功！' })
                        if (this.type === '买卖房源') {
                            this.$router.push('/deal')
                        } else {
                            this.$router.push('/rent')
                        }
                    } else {
                        this.$toast(res.msg)
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            onFinish({ selectedOptions }) {
                if (selectedOptions.length === 3) {
                    this.show = false
                    this.form.unit = selectedOptions.map((option) => option.text).join()
                }
            },
            onFinish1({ selectedOptions }) {
                if (selectedOptions.length === 2) {
                    this.show1 = false
                    this.form.floor = selectedOptions.map((option) => option.text).join()
                }
            },
            onFinish2({ selectedOptions }) {
                if (selectedOptions.length === 2) {
                    this.show2 = false
                    this.form.termsofpayment = selectedOptions.map((option) => option.text).join()
                }
            },
            upVideos(file) {
                if (Array.isArray(file)) {
                    file.forEach(e => {
                        e.status = 'uploading'
                        e.message = '上传中...'
                        const pa = new FormData()
                        pa.append('file', e.file)
                        uploadFile(pa).then(res => {
                            if (res.code === 200) {
                                e.status = 'success'
                                e.message = '上传成功'
                                e.url = res.fileName
                            } else {
                                e.status = 'failed'
                                e.message = '上传失败'
                            }
                        }).catch(() => {
                            e.status = 'failed'
                            e.message = '上传失败'
                        })
                    })
                } else {
                    file.status = 'uploading'
                    file.message = '上传中...'
                    const pa = new FormData()
                    pa.append('file', file.file)
                    uploadFile(pa).then(res => {
                        if (res.code === 200) {
                            file.status = 'success'
                            file.message = '上传成功'
                            file.url = res.fileName
                        } else {
                            file.status = 'failed'
                            file.message = '上传失败'
                        }
                    }).catch(() => {
                        file.status = 'failed'
                        file.message = '上传失败'
                    })
                }
            },
            onConfirm(val) {
                this.type = val
                this.showType = false
            },
            selArea(val) {
                this.showArea = false
                this.form.area = val.area
                this.form.areaid = val.id
                if (val.children) {
                    this.districtArr = val.children
                } else {
                    getDict(val.id).then(res => {
                        if (res.code === 200) {
                            this.districtArr = res.data
                            val.children = res.data
                        }
                    })
                }
            },
            selDis(val) {
                this.form.districtid = val.id
                this.form.district = val.district
                this.showDis = false
            },
            selFace(val) {
                this.form.face = val.dictLabel
                this.showFace = false
            },
            selDeco(val) {
                this.form.decoration = val.dictLabel
                this.showDeco = false
            },
            selChan(val) {
                this.form1.property = val.dictLabel
                this.form1.propertyid = val.dictValue
                this.showChan = false
            },
            selNian(val) {
                this.form1.buildingages = val
                this.showNian = false
            },
            selKan(val) {
                this.form1.inspection = val.dictLabel
                this.form1.inspectionid = val.dictValue
                this.showKan = false
            },
            selFu(val) {
                this.form.termsofpayment = val.dictLabel
                this.form.termsofpayment1 = val.dictValue
                this.show2 = false
            }
        },
        created() {
            // 户型数组
            let idx = 0
            for (let i = 1; i <= 7; i++) {
                const item = { text: i + '室', value: idx, children: [] }
                idx += 1
                for (let j = 1; j <= 7; j++) {
                    item.children.push({ text: j + '厅', value: idx, children: [] })
                    idx += 1
                    for (let k = 1; k <= 7; k++) {
                        item.children[j - 1].children.push({ text: k + '卫', value: idx })
                        idx += 1
                    }
                }
                this.options.push(item)
            }
            // 楼层数组
            let idxz = 0
            for (let i = 1; i <= 32; i++) {
                const item = { text: '第' + i + '层', value: idxz, children: [] }
                idxz += 1
                for (let j = 1; j <= 32; j++) {
                    item.children.push({ text: '共' + j + '层', value: idxz })
                    idxz += 1
                }
                this.options1.push(item)
            }
            // 付款方式数组
            let idxk = 0
            for (let i = 1; i <= 13; i++) {
                const item = { text: '押' + i, value: idxk, children: [] }
                idxk += 1
                for (let j = 1; j <= 13; j++) {
                    item.children.push({ text: '付' + j, value: idxk })
                    idxk += 1
                }
                this.options2.push(item)
            }
            // 年份数组
            const year = new Date().getFullYear()
            for (let i = year; i > year - 200; i--) {
                this.nianfen.push(i)
            }
            getArea().then(res => {
                if (res.code === 200) this.areaArr = res.data
            })
            this.$smartDict.getDict(['vua_face', 'vua_decoration', 'vua_buy_property', 'vua_inspection', 'vua_buy_payment']).then(({
                vua_face,
                vua_decoration,
                vua_buy_property,
                vua_inspection,
                vua_buy_payment
            }) => {
                this.faceArr = vua_face
                this.decorationArr = vua_decoration
                this.chanquan = vua_buy_property
                this.kanfang = vua_inspection
                this.fukuan = vua_buy_payment
            })
        }
    }
</script>

<style lang="scss" scoped>
.form-title{
    width: calc(100vw - 32px);
    height: 30px;
    line-height: 30px;
    box-sizing: border-box;
    border-bottom: 1px solid #1989fa;
    color: #1989fa;
    font-size: 16px;
    font-weight: bold;
    margin: 12px 16px;
}
</style>