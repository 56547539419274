<template>
    <div style="overflow:hidden;">
        <!-- <van-field v-model="value" label="" placeholder="请输入用户名" />
        <van-field v-model="value1" label="" placeholder="请输入用户名" /> -->
        <img src="../assets/logo1.png" alt="" class="logo" />
        <van-field v-model="user" label="" placeholder="请输入用户名" class="my-inp" />
        <van-field v-model="pass" type="password" label="" placeholder="请输入密码" class="my-inp" />
        <van-button round block type="info" native-type="submit" class="my-btn" :loading="loading" loading-text="登录中..." @click="submit">登录</van-button>
    </div>
</template>

<script>
    import { login } from '@/api/api'
    export default {
        data() {
            return {
                user: '',
                pass: '',
                loading: false
            }
        },
        methods: {
            submit() {
                if (!this.user || !this.pass) {
                    this.$toast('输入不能为空！')
                } else {
                    this.loading = true
                    login({
                        username: this.user,
                        password: this.pass
                    }).then(res => {
                        if (res.code === 200) {
                            this.$notify({ type: 'success', message: '登录成功！' })
                            localStorage.setItem('fangwuToken', res.token)
                            setTimeout(() => {
                                this.$router.push('/rent')
                            }, 100)
                        } else {
                            this.$toast(res.msg)
                        }
                    }).finally(() => {
                        this.loading = false
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .logo{
        display: block;
        margin: 12vh auto 10vh auto;
    }
    .my-inp{
        width: 80%;
        background-color: rgb(234, 234, 234, .5);
        margin: 0 auto;
        border-radius: 6px;
        margin-bottom: 20px;
        padding: 12px 16px;
        font-size: 16px;
    }
    .my-btn{
        width: 80%;
        margin: 0 auto;
        padding: 12px 16px;
        font-size: 16px;
        height: 48px;
        margin-top: 50px;
    }
</style>