<template>
    <div>
        <van-search shape="round" v-model="value" placeholder="请输入关键词搜索" background="#fff" @search="sea" @clear="clea" />
        <van-divider />
        <van-dropdown-menu active-color="#1989fa">
            <van-dropdown-item v-model="value1" :options="option1" @change="getStric" />
            <van-dropdown-item v-model="value2" :options="option2" @change="sea" />
            <van-dropdown-item v-model="value3" :options="option3" @change="sea" />
        </van-dropdown-menu>
        <van-divider />

        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
            >
                <div class="list-item" v-for="item in list" :key="item.id" @click="$router.push('/dealDetail?id=' + item.id + '&area=' + item.area + '&dis=' + item.district)">
                    <div class="list-img">
                        <van-image
                            width="100%"
                            height="100px"
                            fit="cover"
                            radius="4px"
                            :src="'https://oss.guoliangleju.com/' + item.imgs"
                        />
                    </div>
                    <div class="list-info">
                        <div class="list-name van-ellipsis">{{ item.owneraddress }}</div>
                        <div class="list-area">
                            {{ item.housingarea }}㎡<span></span>{{ item.floor[0] }}/{{ item.floor[1] }}层<span></span>朝向: {{ item.face | getFace }}
                        </div>
                        <van-row>
                            <van-col span="24">
                                <van-tag type="primary" size="medium" style="margin-right:10px;">{{ item.area }}</van-tag>
                                <van-tag type="primary" size="medium">{{ item.district }}</van-tag>
                            </van-col>
                        </van-row>
                        <div class="list-price">￥{{ item.rent }}/万</div>
                    </div>
                </div>
                <van-empty description="查询数据为空" v-if="list.length === 0 && loading === false" />
            </van-list>
        </van-pull-refresh>
    </div>
</template>

<script>
    import { getDealList, getArea, getDict } from '@/api/api'
    let faceArr = []
    export default {
        data() {
            return {
                value: '',
                value1: 'zzz',
                value2: 'zzz',
                value3: 'zzz',
                option1: [{ text: '全部区域', value: 'zzz' }],
                option2: [{ text: '全部商圈', value: 'zzz' }],
                option3: [{ text: '全部户型', value: 'zzz' }],
                list: [],
                loading: false,
                finished: false,
                refreshing: false,
                param: {
                    pageNum: 1,
                    pageSize: 10
                },
                total: 0
            }
        },
        methods: {
            onLoad(clear) {
                this.loading = true
                const param = {
                    pageNum: this.param.pageNum,
                    pageSize: this.param.pageSize
                }
                if (this.value) param.owneraddress = this.value
                if (this.value1 && this.value1 !== 'zzz') param.areaid = this.value1
                if (this.value2 && this.value2 !== 'zzz') param.districtid = this.value2
                if (this.value3 && this.value3 !== 'zzz') param.huxing = this.value3
                getDealList(param).then(res => {
                    if (res.code === 200) {
                        res.rows.forEach(e => {
                            if (e.floor) {
                                const floor = e.floor.split(',')
                                e.floor = floor.length === 2 ? floor : ['', '']
                            }
                            if (e.imgs) {
                                const imgs = e.imgs.split(',')
                                e.imgs = imgs[0]
                            }
                        })
                        this.list = clear ? res.rows : this.list.concat(res.rows)
                        this.total = res.total
                        const cur = (this.param.pageNum - 1) * this.param.pageSize + res.rows.length
                        this.finished = cur >= res.total ? true : false
                        if (!this.finished) {
                            this.param.pageNum += 1
                        }
                    } else {
                        this.total = 0
                        this.list = []
                        this.finished = true
                    }
                }).finally(() => {
                    this.loading = false
                    this.refreshing = false
                })
            },
            onRefresh() {
                this.param.pageNum = 1
                this.finished = false
                this.onLoad(true)
            },
            getStric(id) {
                if (id == 'zzz') {
                    this.option2 = [{ text: '全部商圈', value: 'zzz' }]
                    this.value2 = 'zzz'
                } else {
                    getDict(id).then(res => {
                        if (res.code === 200) {
                            res.data.forEach(e => {
                                e.text = e.district
                                e.value = e.id
                            })
                            this.option2 = res.data
                            this.option2.unshift({ text: '全部商圈', value: 'zzz' })
                        }
                    })
                }
                this.sea()
            },
            sea() {
                this.param = {
                    pageNum: 1,
                    pageSize: 10
                }
                this.finished = false
                this.onLoad(true)
            },
            clea() {
                this.value = ''
                this.sea()
            }
        },
        filters: {
            getFace(id) {
                if (faceArr.length > 0) {
                    const data = faceArr.find(e => e.dictValue == id)
                    return data ? data.dictLabel : ''
                } else {
                    return ''
                }
            }
        },
        created() {
            this.$smartDict.getDict(['vua_huixing', 'vua_face']).then(({vua_huixing, vua_face}) => {
                faceArr = vua_face
                vua_huixing.forEach(e => {
                    e.text = e.dictLabel
                    e.value = e.dictValue
                })
                this.option3 = vua_huixing
                this.option3.unshift({ text: '全部户型', value: 'zzz' })
            })
            getArea().then(res => {
                if (res.code === 200) {
                    res.data.forEach(e => {
                        e.text = e.area
                        e.value = e.id
                    })
                    this.option1 = res.data
                    this.option1.unshift({ text: '全部区域', value: 'zzz' })
                }
            })
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep .van-dropdown-menu__bar{
        box-shadow: none;
    }
    ::v-deep .van-divider{
        margin: 0;
    }
    .list-item{
        width: calc(100vw - 32px);
        min-height: 100px;
        margin: 16px;
        overflow: hidden;
    }
    .list-img{
        width: calc(40% - 8px);
        height: 100px;
        float: left;
    }
    .list-info{
        width: calc(60% - 8px);
        min-height: 100px;
        float: right;
    }
    .list-name{
        font-size: 16px;
        color: #333;
    }
    .list-area{
        font-size: 14px;
        color: rgb(92, 92, 92);
        margin: 4px 0;
        span{
            display: inline-block;
            width: 1px;
            height: 14px;
            background-color: rgb(151, 151, 151);
            margin: 0 8px;
            position: relative;
            top: 2px;
        }
    }
    .list-price{
        font-size: 20px;
        color: orange;
        text-indent: -4px;
    }
</style>