import myHttp from './axiosConfig'

const baseUri = "/prod-api"
// 登录
export const login = data => myHttp.post(baseUri+'/login', data, {
    headers: { isToken: false }
}).then(res => res.data)

// 查询字典表  '/system/dict/data/type/' + dictType
export const getDicts = data => myHttp.get(baseUri+'/system/dict/data/type/' + data).then(res => res.data)

// 查询区域
export const getArea = () => myHttp.get(baseUri+'/system/area/all').then(res => res.data)

// 查询商圈
export const getDict = id => myHttp.get(baseUri+'/system/district/all/' + id).then(res => res.data)

// 新增普租房源
export const addRent = data => myHttp.post(baseUri+'/system/puzuhouseinfo', data).then(res => res.data)

// 普租房源列表
export const getRentList = data => myHttp.get(baseUri+'/system/puzuhouseinfo/list', { params: data }).then(res => res.data)

// 普租房源详情
export const getRentDetail = id => myHttp.get(baseUri+'/system/puzuhouseinfo/' + id).then(res => res.data)

// 普租房源查看电话
export const rentPhone = id => myHttp.get(baseUri+'/system/puzuhouseinfo/phone/' + id).then(res => res.data)

// 买卖房源查看电话
export const dealPhone = id => myHttp.get(baseUri+'/system/maimaihouseinfo/phone/' + id).then(res => res.data)

// 买卖房源列表
export const getDealList = data => myHttp.get(baseUri+'/system/maimaihouseinfo/list', { params: data }).then(res => res.data)

// 买卖房源详情
export const getDealDetail = id => myHttp.get(baseUri+'/system/maimaihouseinfo/' + id).then(res => res.data)

// 新增买卖房源
export const addDeal = data => myHttp.post(baseUri+'/system/maimaihouseinfo', data).then(res => res.data)

// 查询个人信息
export const getUserinfo = () => myHttp.get(baseUri+'/system/user/profile').then(res => res.data)

// 上传文件
export const uploadFile = data => myHttp.post(baseUri+'/common/ossupload', data).then(res => res.data)

// 新增普租客户
export const addPuzucustomer = data => myHttp.post(baseUri+'/system/puzucustomer', data).then(res => res.data)

// 新增买卖客户
export const addMaimaicustomer = data => myHttp.post(baseUri+'/system/maimaicustomer', data).then(res => res.data)

// 查询普租客户列表
export const getRentCustomer = data => myHttp.get(baseUri+'/system/puzucustomer/list', { params: data }).then(res => res.data)

// 查询普租客户详情
export const getRentCustomerDetail = id => myHttp.get(baseUri+'/system/puzucustomer/' + id).then(res => res.data)

// 查询买卖客户列表
export const getDealCustomer = data => myHttp.get(baseUri+'/system/maimaicustomer/list', { params: data }).then(res => res.data)

// 查询买卖客户详情
export const getDealCustomerDetail = id => myHttp.get(baseUri+'/system/maimaicustomer/' + id).then(res => res.data)

// 查询公司空房列表
export const getEmptyRoom = data => myHttp.get(baseUri+'/system/entrusthouseroom/list', { params: data }).then(res => res.data)

// 查询公司空房详情
export const getEmptyRoomDetail = id => myHttp.get(baseUri+'/system/entrusthouseinfo/' + id).then(res => res.data)