<template>
    <div id="app">
        <div class="content-main" v-if="$route.path !== '/login'">
            <router-view />
        </div>
        <bot-nav v-if="$route.path !== '/login'" />
        <router-view v-if="$route.path === '/login'" />
    </div>
</template>

<script>
    import BotNav from '@/components/nav.vue'
    export default {
        components: { BotNav }
    }
</script>

<style lang="scss">
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        width: 100%;
        height: 100vh;
    }
    body, p{
        margin: 0;
        padding: 0;
    }
    .content-main{
        width: 100%;
        overflow: hidden;
        position: fixed;
        left: 0;
        top: 0;
        bottom: 50px;
        overflow-y: scroll;
    }
</style>
