<template>
    <div>
        <van-nav-bar
            title="公司空房列表"
            left-text="返回"
            left-arrow
            @click-left="onClickLeft"
        />
        <van-search shape="round" v-model="value" :placeholder="'请输入' + label + '搜索'" background="#fff" @search="sea" @clear="clea">
            <template #label>
                <div style="color:#606266;" @click.stop="toggleSea">{{ label }}</div>
            </template>
        </van-search>
        <van-divider />

        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
            >
                <div class="list-item" v-for="item in list" :key="item.id" @click="$router.push('/emptyRoomDetail?id=' + item.wthouseid + '&area=' + item.areaname + '&dis=' + item.disname)">
                    <div class="list-info">
                        <div class="list-name van-ellipsis">{{ item.hoursename }}</div>
                        <div class="list-area">
                            {{ item.deptName }}<span></span>{{ item.roomstate | getFace }}<span></span>{{ item.userName }}
                        </div>
                        <van-row>
                            <van-col span="24">
                                <van-tag type="primary" size="medium" style="margin-right:10px;">{{ item.areaname }}</van-tag>
                                <van-tag type="primary" size="medium">{{ item.disname }}</van-tag>
                            </van-col>
                        </van-row>
                        <div class="list-price"><span style="margin:0 16px 0 4px;color:#1989FA;">{{ item.roomname }}</span>￥{{ item.rent }}</div>
                    </div>
                </div>
                <van-empty description="查询数据为空" v-if="list.length === 0 && loading === false" />
            </van-list>
        </van-pull-refresh>
    </div>
</template>

<script>
    import { getEmptyRoom } from '@/api/api'
    let faceArr = []
    export default {
        name: 'emptyRoom',
        data() {
            return {
                value: '',
                list: [],
                loading: false,
                finished: false,
                refreshing: false,
                param: {
                    pageNum: 1,
                    pageSize: 10
                },
                total: 0,
                label: '房源地址'
            }
        },
        methods: {
            onLoad(clear) {
                this.loading = true
                const param = {
                    pageNum: this.param.pageNum,
                    pageSize: this.param.pageSize,
                    roomstate: 0
                }
                if (this.value && this.label === '房源地址') param.hoursename = this.value
                if (this.value && this.label === '房间名称') param.roomname = this.value
                // 房源地址  hoursename
                // 房间名称  roomname
                getEmptyRoom(param).then(res => {
                    if (res.code === 200) {
                        res.rows.forEach(e => {
                            if (e.floor) {
                                const floor = e.floor.split(',')
                                e.floor = floor.length === 2 ? floor : ['', '']
                            }
                            if (e.imgs) {
                                const imgs = e.imgs.split(',')
                                e.imgs = imgs[0]
                            }
                        })
                        this.list = clear ? res.rows : this.list.concat(res.rows)
                        this.total = res.total
                        const cur = (this.param.pageNum - 1) * this.param.pageSize + res.rows.length
                        this.finished = cur >= res.total ? true : false
                        if (!this.finished) {
                            this.param.pageNum += 1
                        }
                    } else {
                        this.total = 0
                        this.list = []
                        this.finished = true
                    }
                }).finally(() => {
                    this.loading = false
                    this.refreshing = false
                })
            },
            onRefresh() {
                this.param.pageNum = 1
                this.finished = false
                this.onLoad(true)
            },
            sea() {
                this.param = {
                    pageNum: 1,
                    pageSize: 10
                }
                this.finished = false
                this.onLoad(true)
            },
            clea() {
                this.value = ''
                this.sea()
            },
            onClickLeft() {
                this.$router.back()
            },
            toggleSea() {
                if (this.label === '房源地址') {
                    this.label = '房间名称'
                } else {
                    this.label = '房源地址'
                }
            }
        },
        mounted() {
            this.$smartDict.getDict(['vua_roomstate']).then(({vua_roomstate}) => {
                faceArr = vua_roomstate
            })
        },
        filters: {
            getFace(id) {
                if (faceArr.length > 0) {
                    const data = faceArr.find(e => e.dictValue == id)
                    return data ? data.dictLabel : ''
                } else {
                    return ''
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep .van-dropdown-menu__bar{
        box-shadow: none;
    }
    ::v-deep .van-divider{
        margin: 0;
    }
    .list-item{
        width: calc(100vw - 32px);
        min-height: 100px;
        margin: 16px;
        overflow: hidden;
        border-bottom: 1px solid #eee;
    }
    .list-info{
        width: 100%;
        min-height: 100px;
    }
    .list-name{
        font-size: 16px;
        color: #333;
    }
    .list-area{
        font-size: 14px;
        color: rgb(92, 92, 92);
        margin: 4px 0;
        span{
            display: inline-block;
            width: 1px;
            height: 14px;
            background-color: rgb(151, 151, 151);
            margin: 0 8px;
            position: relative;
            top: 2px;
        }
    }
    .list-price{
        font-size: 20px;
        color: orange;
        text-indent: -4px;
    }
</style>