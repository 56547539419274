<template>
    <div>
        <van-image
            style="display:block;margin:50px auto;"
            fit="cover"
            round
            width="150px"
            height="150px"
            :src="user.avatar ? user.avatar : 'https://img01.yzcdn.cn/vant/cat.jpeg'"
        />
        <van-cell title="用户昵称" :value="user.nickName" icon="manager-o" />
        <van-cell title="手机号码" :value="user.phonenumber" icon="phone-o" />
        <van-cell title="用户邮箱" :value="user.email" icon="envelop-o" />
        <van-cell title="所属部门" :value="user.dept + ' / ' + user.postGroup" icon="cluster-o" />
        <van-cell title="所属角色" :value="user.roleGroup" icon="friends-o" />
        <van-cell title="创建日期" :value="user.createTime" icon="records" />
        <van-cell title="普租客户" is-link icon="todo-list-o" @click="$router.push('/rentCustomer')" />
        <van-cell title="买卖客户" is-link icon="notes-o" @click="$router.push('/dealCustomer')" />
        <van-cell title="公司空房" is-link icon="hotel-o" @click="$router.push('/emptyRoom')" />
    </div>
</template>

<script>
    import { getUserinfo } from '@/api/api'
    export default {
        data() {
            return {
                user: {
                    nickName: '',
                    postGroup: '',
                    roleGroup: '',
                    phonenumber: '',
                    email: '',
                    createTime: '',
                    dept: '',
                    avatar: ''
                }
            }
        },
        mounted() {
            const load = this.$toast.loading({
                duration: 0,
                message: '加载中...',
                forbidClick: true,
                loadingType: 'spinner',
            })
            getUserinfo().then(res => {
                if (res.code == 200) {
                    const { nickName, phonenumber, email, createTime, dept, avatar } = res.data
                    this.user = {
                        nickName,
                        phonenumber,
                        email,
                        createTime,
                        avatar,
                        postGroup: res.postGroup,
                        roleGroup: res.roleGroup,
                        dept: dept.deptName || ''
                    }
                }
            }).finally(() => {
                load.clear()
            })
        }
    }
</script>
