import { getDicts } from "../api/api"

// export default function dict(vue) {
//     vue.SmartDict = new SmartDict()
// }

export default class SmartDict {
    constructor() {
        this.dictMap = new Map()  // 缓存字典表
    }

    /*
    * 设置字典表值
    * dictType 字典表类型
    * dict 字典表数组
    * 示例  setDict('vua_decoration', [{...}, {...}])
    * 无返回值
    */
    setDict(dictType, dict) {
        this.dictMap.set(dictType, dict)
    }

    /*
    * 删除字典表值
    * dictType 字典表类型  String 或 String数组
    * 示例  delDict('vua_decoration') | delDict(['vua_decoration', 'vua_face'])
    * 无返回值
    */
    delDict(dictTypes) {
        let delList = []
        if (typeof dictTypes === 'string') delList.push(dictTypes)
        if (Array.isArray(dictTypes)) delList = dictTypes
        delList.forEach(e => this.dictMap.delete(e))
    }

    /*
    * 修改字典表 类型
    * oldDictType 修改前字典表类型
    * newDictType 修改后字典表类型
    * 示例  renameDict('vua_decoration_1', 'vua_decoration')
    * 无返回值
    */
    renameDict(oldDictType, newDictType) {
        if (this.dictMap.has(oldDictType)) {
            const data = this.dictMap.get(oldDictType)
            this.dictMap.set(newDictType, data)
            this.dictMap.delete(oldDictType)
        }
    }

    /*
    * 取字典表值
    * dictTypes 为 String 或 String数组 - 'vua_decoration' | ['vua_decoration', 'vua_huixing']
    * 示例  getDict('vua_decoration') 或 getDict(['vua_decoration', 'vua_huixing'])
    * 参数为单个 String 时 返回值为单个数组 [{...}, {...}]
    * 参数为 String数组 时 返回值为Object { vua_decoration: [{...}, {...}], vua_huixing: [{...}, {...}] }
    * getDict(['vua_decoration', 'vua_huixing']).then(({ vua_decoration, vua_huixing }) => {})
    */
    getDict(dictTypes) {
        return new Promise((resolve, reject) => {
            if (!dictTypes || dictTypes.length <= 0) resolve([])
            if (typeof dictTypes === 'string') {
                if (this.dictMap.has(dictTypes)) {
                    resolve(this.dictMap.get(dictTypes))
                } else {
                    getDicts(dictTypes).then(res =>{
                        if (res.code == 200) {
                            this.dictMap.set(dictTypes, res.data)
                            resolve(res.data)
                        } else {
                            resolve([])
                        }
                    }).catch(err => {
                        reject(err)
                    })
                }
            }
            if (Array.isArray(dictTypes)) {
                const resMap = {}
                const reqQueue = []
                dictTypes.forEach(e => {
                    if (typeof e === 'string') {
                        if (this.dictMap.has(e)) {
                            resMap[e] = this.dictMap.get(e)
                        } else {
                            reqQueue.push(e)
                        }
                    }
                })
                if (reqQueue.length > 0) {
                    Promise.all(reqQueue.map(e => getDicts(e))).then(arr => {
                        reqQueue.forEach((key, idx) => {
                            if (arr[idx].code === 200) this.dictMap.set(key, arr[idx].data)
                            resMap[key] = arr[idx].code === 200 ? arr[idx].data : []
                        })
                        resolve(resMap)
                    }).catch(err => {
                        reject(err)
                    })
                } else {
                    resolve(resMap)
                }
            }
        })
    }
    /*
    * 传字典表的 dictLabel 值
    * dictType 字典表类型  dictValue 字典 key 值
    * 示例  getDictLabel('vua_decoration', '1')
    * 返回 String
    */
    getDictLabel(dictType, dictValue) {
        return new Promise((resolve, reject) => {
            if (typeof dictType === 'string') {
                if (this.dictMap.has(dictType)) {
                    const arr = this.dictMap.get(dictType)
                    const idx = arr.findIndex(item => item.dictValue == dictValue)
                    resolve(idx > -1 ? arr[idx].dictLabel : '')
                } else {
                    getDicts(dictType).then(res =>{
                        if (res.code == 200) {
                            this.dictMap.set(dictType, res.data)
                            const idx = res.data.findIndex(item => item.dictValue == dictValue)
                            resolve(idx > -1 ? res.data[idx].dictLabel : '')
                        } else {
                            resolve('')
                        }
                    }).catch(err => {
                        reject(err)
                    })
                }
            } else {
                throw new Error(`getDictLabel 方法的参数 dictType 必须为 String 类型！`)
            }
        })
    }
}