<template>
    <div>
        <van-nav-bar
            title="公司空房详情"
            left-text="返回"
            left-arrow
            @click-left="onClickLeft"
        />
        <div style="padding:16px;">
            <van-swipe :autoplay="3000" v-if="images.length > 0">
                <van-swipe-item v-for="(image, index) in images" :key="index">
                    <van-image
                        radius="4px"
                        width="100%"
                        height="200px"
                        fit="cover"
                        :src="'https://oss.guoliangleju.com/' + image"
                    />
                </van-swipe-item>
            </van-swipe>
            <div class="title">
                {{ detail.propertyaddress }}
            </div>
            <div>
                <span class="price">￥{{ detail.rent }}元</span>
                <van-tag type="primary" size="medium" style="margin-right:10px;">{{ area }}</van-tag>
                <van-tag type="primary" size="medium">{{ dis }}</van-tag>
            </div>
            <div class="desc">
                房屋面积：{{ detail.housingarea }}㎡<span></span>楼层：{{ detail.floor[0] }}/{{ detail.floor[1] }}层<br />
                户型：{{ detail.unit[0] }}室{{ detail.unit[1] }}厅{{ detail.unit[2] }}卫<span></span>朝向：{{ detail.face }}<br />
                装修：{{ detail.decoration }}<span></span>付款方式：押{{ detail.termsofpayment[0] }}付{{ detail.termsofpayment[1] }}<br />
                业主姓名：{{ detail.ownername }}
            </div>

            <video
                :src="'https://oss.guoliangleju.com/' + image"
                style="width:100%;height:auto;margin-top:14px;"
                v-for="image in videos"
                :key="image"
                controls
                :autoplay="false"
                preload="auto"
            ></video>
            <img :src="'https://oss.guoliangleju.com/' + image" alt="" style="width:100%;height:auto;margin-top:14px;" v-for="image in images" :key="image" />
        </div>
    </div>
</template>

<script>
    import { getEmptyRoomDetail } from '@/api/api'
    export default {
        name: 'emptyRoomDetail',
        data() {
            return {
                images: [],
                videos: [],
                show: false,
                detail: {
                    rent: '',
                    housingarea: '',
                    floor: [],
                    face: '',
                    termsofpayment: [],
                    decoration: '',
                    ownername: '',
                    unit: [],
                    propertyaddress: ''
                },
                area: '',
                dis: '',
                faceArr: [],
                phone: ''
            }
        },
        methods: {
            onClickLeft() {
                this.$router.back()
            }
        },
        mounted() {
            const { area, dis } = this.$route.query
            this.area = area
            this.dis = dis
            const load = this.$toast.loading({
                duration: 0,
                message: '加载中...',
                forbidClick: true,
                loadingType: 'spinner',
            })
            getEmptyRoomDetail(this.$route.query.id).then(res => {
                if (res.code === 200) {
                    const floor = res.data.floor.split(',')
                    res.data.floor = floor.length === 2 ? floor : ['--', '--']
                    const termsofpayment = res.data.termsofpayment.split(',')
                    res.data.termsofpayment = termsofpayment.length === 2 ? termsofpayment : ['--', '--']
                    const unit = res.data.unit.split(',')
                    res.data.unit = unit.length === 3 ? unit : ['--', '--', '--']
                    this.detail = res.data
                    if (res.data.imgs) this.images = res.data.imgs.split(',')
                    if (res.data.videourl) this.videos = res.data.videourl.split(',')

                    this.$smartDict.getDict(['vua_face', 'vua_decoration']).then(({vua_face, vua_decoration}) => {
                        const { face, decoration } = this.detail
                        const f = vua_face.find(e => e.dictValue == face)
                        const d = vua_decoration.find(e => e.dictValue == decoration)
                        this.detail.face = f ? f.dictLabel : '--'
                        this.detail.decoration = d ? d.dictLabel : '--'
                    })
                }
            }).finally(() => {
                load.clear()
            })
        }
    }
</script>

<style lang="scss" scoped>
    .title{
        font-size: 20px;
        color: #333;
        margin: 10px 0;
    }
    .price{
        font-size: 16px;
        color: orange;
        margin-right: 14px;
    }
    .desc{
        font-size: 14px;
        color: rgb(116, 116, 116);
        margin-top: 14px;
        span{
            display: inline-block;
            width: 1px;
            height: 14px;
            background-color: rgb(151, 151, 151);
            margin: 0 8px;
            position: relative;
            top: 2px;
        }
        p{
            display: inline;
            color: #1989fa;
        }
    }
</style>