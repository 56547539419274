import Vue from 'vue'
import VueRouter from 'vue-router'
import rent from '@/views/rent'
import add from '@/views/add'
import deal from '@/views/deal'
import user from '@/views/user'
import rentDetail from '@/views/rentDetail'
import dealDetail from '@/views/dealDetail'
import rentCustomer from '@/views/rentCustomer'
import dealCustomer from '@/views/dealCustomer'
import emptyRoom from '@/views/emptyRoom'
import emptyRoomDetail from '@/views/emptyRoomDetail'
import rentCustomerDetail from '@/views/rentCustomerDetail'
import dealCustomerDetail from '@/views/dealCustomerDetail'
import login from '@/views/login'

Vue.use(VueRouter)

const routes = [
    {
        path: '/rent',
        name: 'rent',
        component: rent
    },
    {
        path: '/add',
        name: 'add',
        component: add
    },
    {
        path: '/deal',
        name: 'deal',
        component: deal
    },
    {
        path: '/user',
        name: 'user',
        component: user
    },
    {
        path: '/rentDetail',
        name: 'rentDetail',
        component: rentDetail
    },
    {
        path: '/dealDetail',
        name: 'dealDetail',
        component: dealDetail
    },
    {
        path: '/rentCustomer',
        name: 'rentCustomer',
        component: rentCustomer
    },
    {
        path: '/emptyRoom',
        name: 'emptyRoom',
        component: emptyRoom
    },
    {
        path: '/emptyRoomDetail',
        name: 'emptyRoomDetail',
        component: emptyRoomDetail
    },
    {
        path: '/dealCustomer',
        name: 'dealCustomer',
        component: dealCustomer
    },
    {
        path: '/rentCustomerDetail',
        name: 'rentCustomerDetail',
        component: rentCustomerDetail
    },
    {
        path: '/dealCustomerDetail',
        name: 'dealCustomerDetail',
        component: dealCustomerDetail
    },
    {
        path: '/login',
        name: 'login',
        component: login
    },
    {
        path: '*',
        redirect: '/login'
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) =>{
    const token = localStorage.getItem('fangwuToken')
    if (to.path === '/login' && token) {
        next('/rent')
    }
    if (to.path !== '/login' && !token) {
        next('/login')
    }
    next()
})

export default router
